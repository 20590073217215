.sdg {

  &.toselect {
    opacity: .7;

    &.active, &:hover {
      opacity: 1;
    }
  }


  &.sdg_1 {
    &.std {
      background-color: #E5233E;
      color: #fff;
    }

    &.revert {
      color: #E5233E;
      background-color: #fff;
    }
  }

  &.sdg_2 {
    &.std {
      background-color: #DDA739;
      color: #fff;
    }

    &.revert {
      color: #DDA739;
      background-color: #fff;
    }
  }

  &.sdg_3 {
    &.std {
      background-color: #4DA147;
      color: #fff;
    }

    &.revert {
      color: #4DA147;
      background-color: #fff;
    }
  }

  &.sdg_4 {
    &.std {
      background-color: #C51A2D;
      color: #fff;
    }

    &.revert {
      color: #C51A2D;
      background-color: #fff;
    }
  }

  &.sdg_5 {
    &.std {
      background-color: #F0402B;
      color: #fff;
    }

    &.revert {
      color: #F0402B;
      background-color: #fff;
    }
  }

  &.sdg_6 {
    &.std {
      background-color: #28BFE6;
      color: #fff;
    }

    &.revert {
      color: #28BFE6;
      background-color: #fff;
    }
  }

  &.sdg_7 {
    &.std {
      background-color: #FBC411;
      color: #fff;
    }

    &.revert {
      color: #FBC411;
      background-color: #fff;
    }
  }

  &.sdg_8 {
    &.std {
      background-color: #A31C44;
      color: #fff;
    }

    &.revert {
      color: #A31C44;
      background-color: #fff;
    }
  }

  &.sdg_9 {
    &.std {
      background-color: #F26A2D;
      color: #fff;
    }

    &.revert {
      color: #F26A2D;
      background-color: #fff;
    }
  }

  &.sdg_10 {
    &.std {
      background-color: #E01583;
      color: #fff;
    }

    &.revert {
      color: #E01583;
      background-color: #fff;
    }
  }

  &.sdg_11 {
    &.std {
      background-color: #F89D2A;
      color: #fff;
    }

    &.revert {
      color: #F89D2A;
      background-color: #fff;
    }
  }

  &.sdg_12 {
    &.std {
      background-color: #BF8D2C;
      color: #fff;
    }

    &.revert {
      color: #BF8D2C;
      background-color: #fff;
    }
  }

  &.sdg_13 {
    &.std {
      background-color: #407E46;
      color: #fff;
    }

    &.revert {
      color: #407E46;
      background-color: #fff;
    }
  }

  &.sdg_14 {
    &.std {
      background-color: #1F97D4;
      color: #fff;
    }

    &.revert {
      color: #1F97D4;
      background-color: #fff;
    }
  }

  &.sdg_15 {
    &.std {
      background-color: #58BA47;
      color: #fff;
    }

    &.revert {
      color: #58BA47;
      background-color: #fff;
    }
  }

  &.sdg_16 {
    &.std {
      background-color: #136A9F;
      color: #fff;
    }

    &.revert {
      color: #136A9F;
      background-color: #fff;
    }
  }

  &.sdg_17 {
    &.std {
      background-color: #12496B;
      color: #fff;
    }

    &.revert {
      color: #12496B;
      background-color: #fff;
    }
  }
}
