.register, .home {

  .pane-left-picture {
    background-color: $th-alternate-light;

    .content {
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90%;
      }
    }
  }

  .pane-left-steps {
    background-color: $th-white;

    .content {
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;

      .steps {
        display: flex;
        flex-direction: column;
        font-size: .8rem;

        .step {

          display: flex;
          align-items: center;
          margin-bottom: 1.7rem;

          .name {
            font-size: 1.1rem;
          }
          .marker {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: .5rem;

            &.inactive {
              border: solid 2px $th-grey-medium;
              color: $th-grey-medium;
            }

            &.active {
              border: solid 2px $th-alternate-medium;
              color: $th-white;
              background-color: $th-alternate-medium;
            }
          }
        }
      }
    }
  }

  .pane-right {
    background-color: $th-alternate-lighter;

    .content {
      position: relative;
      top: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > .row {
        width: 85%;
      }

      .notice {
        font-size: .8rem;
        color: $th-grey-dark;

        a, a:hover, a.active {
          color: $th-black;
        }
      }

      .themes {
        .card {
          padding: 1rem;
          margin-bottom: 1.5rem;

          &.active {
            border: solid 2px $th-alternate-medium;
          }

          .ico {
            background-color: $th-alternate-light;
            color: $th-alternate-dark;
            padding: .4rem .55rem;
            border-radius: .5rem;
            margin-right: .75rem;
          }

          .name {
            font-weight: bold;
            font-size: .9rem;
          }
        }
      }
    }
  }
}
