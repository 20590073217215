.avatar {
  border-radius: .25rem;
  color: $th-white;
  font-weight: bold;

  &.circle {
    border-radius: 50%;
  }

  &.status {
    width: inherit;
  }

  &.xs {
    @include xs;
    width: 23px;
    height: 23px;
  }

  &.sm {
    @include sm;
    width: 30px;
    height: 30px;
  }

  &.md {
    @include md;
    width: 35px;
    height: 35px;
  }

  &.lg {
    @include lg;
    width: 40px;
    height: 40px;
  }

  &.xl {
    @include xl;
    width: 50px;
    height: 50px;
  }
}

.hold {
  position: relative;

  .notif {
    position: absolute;
    bottom: 2px;
    right: 5px;
    border: solid 2px $th-grey-light;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    background-color: red;
  }

  &.stacked:not(:first-child) {
    margin-left: -2%;
  }
}

