* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html {
  min-height: 100%;
  height: 100%;
}

body {
  //background-image: url('/assets/img/bubble-background.jpg');
  background-color: $th-grey-base;
  color: $th-black;
}

.modal-content {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w-33 {
  width: 33%;
}
