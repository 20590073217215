a.sec--menu {
  @include xs;
  cursor: pointer;
  transition: all 200ms;

  &.hover {
    color: $th-em-dark;
  }

  &.active {
    font-weight: bold;
    color: $th-em-dark;
  }
}
