.nav-tabs {
  border: none;

  .nav-link {
    margin-bottom: -1px;
    border: none;

    &.active {
      border-bottom: solid 3px $th-alternate-darker !important;
      font-weight: bold;
    }

    &:hover {
      border-bottom: solid 3px $th-alternate-medium;
    }

    &.disabled {
      color: #bdc0c4;
    }
  }
}

