.title {

  &.xs {
    font-size: .7rem;
  }

  &.sm {
    font-size: 1rem;
  }

  &.lg {
    font-size: 1.2rem;
  }

  &.xl {
    font-size: 1.4rem;
  }

  color: $th-grey-darker;
}

