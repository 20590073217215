.badge-rounded {
  padding: .25em .5em;
  border-radius: .5rem;
}

.badge-sm {
  font-size: .675rem;
}

.badge-md {
  font-size: .875rem;
}

.badge-lg {
  font-size: 1rem;
}

.badge-xl {
  font-size: 1.2rem;
}

.badge {
  &.status {
    padding: .5rem;
  }

  font-weight: normal;
}

.badge-taamana {
  color: $th-white;
  background-color: $th-alternate-medium;
}
