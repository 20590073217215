$th-white: #fff;
$th-grey-lighter: #fcfcfc;
$th-grey-light: #f3f3f3;
$th-grey-base: #eef0f3;
$th-grey-medium: #c6c6c6;
$th-grey-dark: #acacac;
$th-grey-darker: #696969;
$th-black: #212529;

$th-main-lighter-bg: #ffffff;
$th-main-lighter-color: #3b3b3b;

//$th-main-bg: #f9fcfa;
$th-main-bg: #e1f1ef;
$th-main-color: #3b3b3b;

$th-alternate-lighter: #F6FAF9;
$th-alternate-light: #B5E2DB;
$th-alternate-medium: #6cc3b3;
$th-alternate-dark: #57a99b;
$th-alternate-darker: #45887c;

$th_purple : #A64D79;
$th_teal : #0097A7;

$th-em-dark: #2f49d1;
$th-em-mid: #c0c8f3;
$th-em-light: #f0f2ff;

$th-aw-dark: #c66900;
$th-aw-mid: #ff9800;
$th-aw-light: #fdf8ef;

$th-ea-dark: #2e7d32;
$th-ea-mid: #66bb6a;
$th-ea-light: #e8f5e9;

$th-sp-dark: #c62828;
$th-sp-mid: #f44336;
$th-sp-light: #ffebee;

//@font-face {
//  font-family: "Patua";
//  src: local("Patua"), url(../../assets/font/PatuaOne-Regular.ttf) format("truetype");
//}
