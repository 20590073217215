.btn-taamana-inv {
  color: $th-alternate-dark;
  background-color: $th-alternate-light;
  border-color: $th-alternate-light;
}

.btn-taamana-inv:not(:disabled):not(.disabled).active,
.btn-taamana-inv:not(:disabled):not(.disabled):active,
.show > .btn-taamana-inv.dropdown-toggle {
  color: $th-alternate-dark;
  background-color: $th-alternate-medium;
  border-color: $th-alternate-medium;
}

.btn-taamana-inv:hover {
  color: $th-alternate-dark;
  background-color: $th-alternate-medium;
  border-color: $th-alternate-medium;
}

.btn-taamana {
  color: $th-white;
  background-color: $th-alternate-medium;
  border-color: $th-alternate-medium;
}

.btn-taamana:not(:disabled):not(.disabled).active,
.btn-taamana:not(:disabled):not(.disabled):active,
.show > .btn-taamana.dropdown-toggle {
  color: $th-white;
  background-color: $th-alternate-dark;
  border-color: $th-alternate-dark;
}

.btn-taamana:hover {
  color: $th-white;
  background-color: $th-alternate-dark;
  border-color: $th-alternate-dark;
}

.btn-group-xs>.btn, .btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

//.btn {
//  border-radius: .5rem;
//  padding: 0.3rem 1.3rem;
//}

.btn-group {
  cursor: pointer;
}

.sb-circles-dark.sb-group .sb-icon {
    width: 24px;
    min-height: 24px;
    font-size: 16px;
}