app-header {
  nav.navbar {
    background-color: $th-white;
    border-bottom: 1px solid rgba(0,0,0,.125);
    height: 65px;

    .dropdown-toggle::after {
      content: none;
    }

    .app-name {
      font-weight: bold;
      margin: 0;
      color: $th-black;
    }
  }
}
