.file {

  border: solid 2px transparent;
  border-radius: .25rem;

  &.xs {
    max-height: 75px;
  }

  &.sm {
    max-height: 100px;
  }

  &.md {
    max-height: 200px;
  }

  &.lg {
    max-height: 350px;
  }

  &.xl {
    max-height: 500px;
  }

  &.max {
    width: 100%;
  }
}
