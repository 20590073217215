.mdtext {
  font-size: 0.9rem;
}

.xs {
  font-size: 0.8rem;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 1.3rem;
}

.lg {
  font-size: 1.75rem;
}

.xl {
  font-size: 2rem;
}

.smaller {
  font-size: 70%;
  font-weight: 400;
}

.notification {
  color: #212529 !important;
  font-weight: bold;
}

/*
 * Sidebar
 */
.sidebar {
  top: 65px;
  bottom: 0;
  z-index: 100;
  /* Behind the navbar */
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}
.sidebar-sticky {
  position: relative;
  top: 0;
  height: 100vh;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.network {
  background-color: #F6FAF9;
}

* {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html {
  min-height: 100%;
  height: 100%;
}

body {
  background-color: #eef0f3;
  color: #212529;
}

.modal-content {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w-33 {
  width: 33%;
}

app-header nav.navbar {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 65px;
}
app-header nav.navbar .dropdown-toggle::after {
  content: none;
}
app-header nav.navbar .app-name {
  font-weight: bold;
  margin: 0;
  color: #212529;
}

.avatar {
  border-radius: 0.25rem;
  color: #fff;
  font-weight: bold;
}
.avatar.circle {
  border-radius: 50%;
}
.avatar.status {
  width: inherit;
}
.avatar.xs {
  font-size: 0.8rem;
  width: 23px;
  height: 23px;
}
.avatar.sm {
  font-size: 1rem;
  width: 30px;
  height: 30px;
}
.avatar.md {
  font-size: 1.3rem;
  width: 35px;
  height: 35px;
}
.avatar.lg {
  font-size: 1.75rem;
  width: 40px;
  height: 40px;
}
.avatar.xl {
  font-size: 2rem;
  width: 50px;
  height: 50px;
}

.hold {
  position: relative;
}
.hold .notif {
  position: absolute;
  bottom: 2px;
  right: 5px;
  border: solid 2px #f3f3f3;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  background-color: red;
}
.hold.stacked:not(:first-child) {
  margin-left: -2%;
}

.wallet--title {
  font-size: 0.8rem;
}

.wallet--filter {
  width: 250px;
}

a {
  color: #696969;
}
a.xs {
  font-size: 0.7rem;
}
a.sm {
  font-size: 0.85rem;
}
a.ico {
  color: #57a99b;
}
a:hover {
  text-decoration: none;
  color: #212529;
}
a.taamana {
  color: #6cc3b3;
}
a.taamana:hover, a.taamana.active, a.taamana.focus {
  color: #57a99b;
}

.sb-icon {
  box-shadow: none !important;
}

.register .pane-left-picture, .home .pane-left-picture {
  background-color: #B5E2DB;
}
.register .pane-left-picture .content, .home .pane-left-picture .content {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .pane-left-picture .content img, .home .pane-left-picture .content img {
  width: 90%;
}
.register .pane-left-steps, .home .pane-left-steps {
  background-color: #fff;
}
.register .pane-left-steps .content, .home .pane-left-steps .content {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .pane-left-steps .content .steps, .home .pane-left-steps .content .steps {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}
.register .pane-left-steps .content .steps .step, .home .pane-left-steps .content .steps .step {
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;
}
.register .pane-left-steps .content .steps .step .name, .home .pane-left-steps .content .steps .step .name {
  font-size: 1.1rem;
}
.register .pane-left-steps .content .steps .step .marker, .home .pane-left-steps .content .steps .step .marker {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.register .pane-left-steps .content .steps .step .marker.inactive, .home .pane-left-steps .content .steps .step .marker.inactive {
  border: solid 2px #c6c6c6;
  color: #c6c6c6;
}
.register .pane-left-steps .content .steps .step .marker.active, .home .pane-left-steps .content .steps .step .marker.active {
  border: solid 2px #6cc3b3;
  color: #fff;
  background-color: #6cc3b3;
}
.register .pane-right, .home .pane-right {
  background-color: #F6FAF9;
}
.register .pane-right .content, .home .pane-right .content {
  position: relative;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.register .pane-right .content > .row, .home .pane-right .content > .row {
  width: 85%;
}
.register .pane-right .content .notice, .home .pane-right .content .notice {
  font-size: 0.8rem;
  color: #acacac;
}
.register .pane-right .content .notice a, .register .pane-right .content .notice a:hover, .register .pane-right .content .notice a.active, .home .pane-right .content .notice a, .home .pane-right .content .notice a:hover, .home .pane-right .content .notice a.active {
  color: #212529;
}
.register .pane-right .content .themes .card, .home .pane-right .content .themes .card {
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.register .pane-right .content .themes .card.active, .home .pane-right .content .themes .card.active {
  border: solid 2px #6cc3b3;
}
.register .pane-right .content .themes .card .ico, .home .pane-right .content .themes .card .ico {
  background-color: #B5E2DB;
  color: #57a99b;
  padding: 0.4rem 0.55rem;
  border-radius: 0.5rem;
  margin-right: 0.75rem;
}
.register .pane-right .content .themes .card .name, .home .pane-right .content .themes .card .name {
  font-weight: bold;
  font-size: 0.9rem;
}

.btn-taamana-inv {
  color: #57a99b;
  background-color: #B5E2DB;
  border-color: #B5E2DB;
}

.btn-taamana-inv:not(:disabled):not(.disabled).active,
.btn-taamana-inv:not(:disabled):not(.disabled):active,
.show > .btn-taamana-inv.dropdown-toggle {
  color: #57a99b;
  background-color: #6cc3b3;
  border-color: #6cc3b3;
}

.btn-taamana-inv:hover {
  color: #57a99b;
  background-color: #6cc3b3;
  border-color: #6cc3b3;
}

.btn-taamana {
  color: #fff;
  background-color: #6cc3b3;
  border-color: #6cc3b3;
}

.btn-taamana:not(:disabled):not(.disabled).active,
.btn-taamana:not(:disabled):not(.disabled):active,
.show > .btn-taamana.dropdown-toggle {
  color: #fff;
  background-color: #57a99b;
  border-color: #57a99b;
}

.btn-taamana:hover {
  color: #fff;
  background-color: #57a99b;
  border-color: #57a99b;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-group {
  cursor: pointer;
}

.sb-circles-dark.sb-group .sb-icon {
  width: 24px;
  min-height: 24px;
  font-size: 16px;
}

label {
  font-size: 0.8em;
  color: #6c757d;
  margin-bottom: 0.2rem;
}
label.checkbox {
  color: #212529;
  margin-left: 0.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.notice {
  color: #bdc0c4;
}

.title {
  color: #696969;
}
.title.xs {
  font-size: 0.7rem;
}
.title.sm {
  font-size: 1rem;
}
.title.lg {
  font-size: 1.2rem;
}
.title.xl {
  font-size: 1.4rem;
}

.score {
  color: #57a99b;
  border-radius: 0.5rem;
  font-weight: bold;
  white-space: nowrap !important;
}
.score:not(.inverted) {
  background-color: #F6FAF9;
  color: #57a99b;
}
.score:not(.inverted) i {
  color: #57a99b;
}
.score.inverted {
  background-color: #57a99b;
  color: #F6FAF9;
}
.score.inverted i {
  color: #F6FAF9;
}

.earned {
  color: #2e7d32;
  border-radius: 0.5rem;
  white-space: nowrap !important;
}
.earned:not(.inverted) {
  background-color: #e8f5e9;
  color: #2e7d32;
}
.earned:not(.inverted) i {
  color: #66bb6a;
}
.earned.inverted {
  background-color: #2e7d32;
  color: #e8f5e9;
}
.earned.inverted i {
  color: #e8f5e9;
}

.spend {
  color: #c62828;
  border-radius: 0.5rem;
  white-space: nowrap !important;
}
.spend:not(.inverted) {
  background-color: #ffebee;
  color: #c62828;
}
.spend:not(.inverted) i {
  color: #f44336;
}
.spend.inverted {
  background-color: #c62828;
  color: #ffebee;
}
.spend.inverted i {
  color: #ffebee;
}

.award {
  color: #2f49d1;
  border-radius: 0.5rem;
  font-weight: bold;
  white-space: nowrap !important;
}
.award:not(.inverted) {
  background-color: #f0f2ff;
  color: #2f49d1;
}
.award:not(.inverted) i {
  color: #c0c8f3;
}
.award.inverted {
  background-color: #2f49d1;
  color: #f0f2ff;
}
.award.inverted i {
  color: #f0f2ff;
}

.stars {
  color: #2f49d1;
  font-weight: bold;
  white-space: nowrap !important;
}
.stars:not(.inverted) {
  background-color: #f0f2ff;
  color: #2f49d1;
}
.stars:not(.inverted) i {
  color: #c0c8f3;
}
.stars.inverted {
  background-color: #2f49d1;
  color: #f0f2ff;
}
.stars.inverted i {
  color: #f0f2ff;
}

.score-star {
  position: absolute;
  top: -15px;
  right: calc(50% - 15px);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #57a99b;
  color: #B5E2DB;
}

.score.xs, .award.xs, .earned.xs, .spend.xs, .stars.xs {
  font-size: 0.8rem;
}
.score.sm, .award.sm, .earned.sm, .spend.sm, .stars.sm {
  font-size: 0.8rem;
}
.score.md, .award.md, .earned.md, .spend.md, .stars.md {
  font-size: 1rem;
}
.score.lg, .award.lg, .earned.lg, .spend.lg, .stars.lg {
  font-size: 1.75rem;
}
.score.xl, .award.xl, .earned.xl, .spend.xl, .stars.xl {
  font-size: 2rem;
}

.shares .price {
  font-weight: bold;
  color: #212529;
  text-align: center;
}
.shares .number {
  font-weight: bold;
  text-align: center;
}

.theme .ico {
  background-color: #B5E2DB;
  color: #57a99b;
  border: solid 3px #B5E2DB;
  opacity: 0.7;
  border-radius: 0.25rem;
}
.theme .ico.active {
  color: #212529;
  opacity: 1;
}
.theme.card {
  min-height: 70px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border: solid 2px #B5E2DB;
}
.theme.card.active, .theme.card:hover {
  border: solid 2px #45887c;
  opacity: 1;
  color: #F6FAF9;
  background-color: #45887c;
}

a.sec--menu {
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 200ms;
}
a.sec--menu.hover {
  color: #2f49d1;
}
a.sec--menu.active {
  font-weight: bold;
  color: #2f49d1;
}

.notification.mark {
  height: 6px;
  width: 6px;
  background-color: red;
  border-radius: 50%;
}

.card {
  transition: all 200ms;
}
.card.transparent {
  background-color: transparent !important;
  border: none;
}
.card .card-footer {
  background-color: transparent;
  border-top: none;
}
.card .card-header.bt {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card .card-title {
  margin-bottom: 1.5rem !important;
  font-weight: bold;
}
.card.link {
  cursor: pointer;
}
.card.link.active:not(.transparent), .card.link:hover:not(.transparent) {
  border-color: #acacac;
}
.card .card-img-top.profile {
  max-height: 150px;
  object-fit: cover;
}
.card .card-img-top.action {
  max-height: 150px;
  object-fit: cover;
}

.badge-rounded {
  padding: 0.25em 0.5em;
  border-radius: 0.5rem;
}

.badge-sm {
  font-size: 0.675rem;
}

.badge-md {
  font-size: 0.875rem;
}

.badge-lg {
  font-size: 1rem;
}

.badge-xl {
  font-size: 1.2rem;
}

.badge {
  font-weight: normal;
}
.badge.status {
  padding: 0.5rem;
}

.badge-taamana {
  color: #fff;
  background-color: #6cc3b3;
}

.sdg.toselect {
  opacity: 0.7;
}
.sdg.toselect.active, .sdg.toselect:hover {
  opacity: 1;
}
.sdg.sdg_1.std {
  background-color: #E5233E;
  color: #fff;
}
.sdg.sdg_1.revert {
  color: #E5233E;
  background-color: #fff;
}
.sdg.sdg_2.std {
  background-color: #DDA739;
  color: #fff;
}
.sdg.sdg_2.revert {
  color: #DDA739;
  background-color: #fff;
}
.sdg.sdg_3.std {
  background-color: #4DA147;
  color: #fff;
}
.sdg.sdg_3.revert {
  color: #4DA147;
  background-color: #fff;
}
.sdg.sdg_4.std {
  background-color: #C51A2D;
  color: #fff;
}
.sdg.sdg_4.revert {
  color: #C51A2D;
  background-color: #fff;
}
.sdg.sdg_5.std {
  background-color: #F0402B;
  color: #fff;
}
.sdg.sdg_5.revert {
  color: #F0402B;
  background-color: #fff;
}
.sdg.sdg_6.std {
  background-color: #28BFE6;
  color: #fff;
}
.sdg.sdg_6.revert {
  color: #28BFE6;
  background-color: #fff;
}
.sdg.sdg_7.std {
  background-color: #FBC411;
  color: #fff;
}
.sdg.sdg_7.revert {
  color: #FBC411;
  background-color: #fff;
}
.sdg.sdg_8.std {
  background-color: #A31C44;
  color: #fff;
}
.sdg.sdg_8.revert {
  color: #A31C44;
  background-color: #fff;
}
.sdg.sdg_9.std {
  background-color: #F26A2D;
  color: #fff;
}
.sdg.sdg_9.revert {
  color: #F26A2D;
  background-color: #fff;
}
.sdg.sdg_10.std {
  background-color: #E01583;
  color: #fff;
}
.sdg.sdg_10.revert {
  color: #E01583;
  background-color: #fff;
}
.sdg.sdg_11.std {
  background-color: #F89D2A;
  color: #fff;
}
.sdg.sdg_11.revert {
  color: #F89D2A;
  background-color: #fff;
}
.sdg.sdg_12.std {
  background-color: #BF8D2C;
  color: #fff;
}
.sdg.sdg_12.revert {
  color: #BF8D2C;
  background-color: #fff;
}
.sdg.sdg_13.std {
  background-color: #407E46;
  color: #fff;
}
.sdg.sdg_13.revert {
  color: #407E46;
  background-color: #fff;
}
.sdg.sdg_14.std {
  background-color: #1F97D4;
  color: #fff;
}
.sdg.sdg_14.revert {
  color: #1F97D4;
  background-color: #fff;
}
.sdg.sdg_15.std {
  background-color: #58BA47;
  color: #fff;
}
.sdg.sdg_15.revert {
  color: #58BA47;
  background-color: #fff;
}
.sdg.sdg_16.std {
  background-color: #136A9F;
  color: #fff;
}
.sdg.sdg_16.revert {
  color: #136A9F;
  background-color: #fff;
}
.sdg.sdg_17.std {
  background-color: #12496B;
  color: #fff;
}
.sdg.sdg_17.revert {
  color: #12496B;
  background-color: #fff;
}

.file {
  border: solid 2px transparent;
  border-radius: 0.25rem;
}
.file.xs {
  max-height: 75px;
}
.file.sm {
  max-height: 100px;
}
.file.md {
  max-height: 200px;
}
.file.lg {
  max-height: 350px;
}
.file.xl {
  max-height: 500px;
}
.file.max {
  width: 100%;
}

.list-group-item.action {
  cursor: pointer;
}
.list-group-item.action:hover {
  background-color: #f3f3f3;
}

.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: none;
}
.nav-tabs .nav-link.active {
  border-bottom: solid 3px #45887c !important;
  font-weight: bold;
}
.nav-tabs .nav-link:hover {
  border-bottom: solid 3px #6cc3b3;
}
.nav-tabs .nav-link.disabled {
  color: #bdc0c4;
}