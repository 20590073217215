.score {
  color: $th-alternate-dark;
  border-radius: .5rem;
  font-weight: bold;
  white-space: nowrap !important;

  &:not(.inverted) {
    background-color: $th-alternate-lighter;
    color: $th-alternate-dark;

    i {
      color: $th-alternate-dark;
    }
  }

  &.inverted {
    background-color: $th-alternate-dark;
    color: $th-alternate-lighter;

    i {
      color: $th-alternate-lighter;
    }
  }
}

.earned {
  color: $th-ea-dark;
  border-radius: .5rem;
  white-space: nowrap !important;

  &:not(.inverted) {
    background-color: $th-ea-light;
    color: $th-ea-dark;

    i {
      color: $th-ea-mid;
    }
  }

  &.inverted {
    background-color: $th-ea-dark;
    color: $th-ea-light;

    i {
      color: $th-ea-light;
    }
  }
}

.spend {
  color: $th-sp-dark;
  border-radius: .5rem;
  white-space: nowrap !important;

  &:not(.inverted) {
    background-color: $th-sp-light;
    color: $th-sp-dark;

    i {
      color: $th-sp-mid;
    }
  }

  &.inverted {
    background-color: $th-sp-dark;
    color: $th-sp-light;

    i {
      color: $th-sp-light;
    }
  }
}

.award {
  color: $th-em-dark;
  border-radius: .5rem;
  font-weight: bold;
  white-space: nowrap !important;

  &:not(.inverted) {
    background-color: $th-em-light;
    color: $th-em-dark;

    i {
      color: $th-em-mid;
    }
  }

  &.inverted {
    background-color: $th-em-dark;
    color: $th-em-light;

    i {
      color: $th-em-light;
    }
  }
}

.stars {
  color: $th-em-dark;
  font-weight: bold;
  white-space: nowrap !important;

  &:not(.inverted) {
    background-color: $th-em-light;
    color: $th-em-dark;

    i {
      color: $th-em-mid;
    }
  }

  &.inverted {
    background-color: $th-em-dark;
    color: $th-em-light;

    i {
      color: $th-em-light;
    }
  }
}

.score-star {
  position: absolute;
  top: -15px;
  right: calc(50% - 15px);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  background-color: $th-alternate-dark;
  color: $th-alternate-light;
}

.score, .award, .earned, .spend, .stars {
  &.xs {
    @include xs;
  }

  &.sm {
    @include xs;
  }

  &.md {
    @include sm;
  }

  &.lg {
    @include lg;
  }

  &.xl {
    @include xl;
  }
}

