.card {
  //border-radius: .5rem;
  transition: all 200ms;
  //border: none;

  &.transparent {
    background-color: transparent !important;
    border: none;
  }

  &:not(.transparent) {
    //border-color: $th-grey-base;
    //box-shadow: 0 .5rem 1rem rgba(61, 61, 61, 0.15) !important;
  }

  .card-body {
    //padding: .7rem;
  }

  .card-body-sub {
    //padding: 0 .7rem .7rem;
  }

  .card-footer {
    background-color: transparent;
    border-top: none;
  }

  .card-header.bt {
    border-top: 1px solid rgba(0, 0, 0, .125);
  }

  .card-title {
    margin-bottom: 1.5rem !important;
    font-weight: bold;
  }

  &.link {
    cursor: pointer;

    &.active, &:hover {

      &:not(.transparent) {
        border-color: $th-grey-dark;
      }
    }
  }

  .card-img-top {
    &.profile {
      max-height: 150px;
      object-fit: cover
    }

    &.action {
      max-height: 150px;
      object-fit: cover
    }
  }
}
