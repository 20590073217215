a {
  color: $th-grey-darker;

  &.xs {
    font-size: .7rem;
  }

  &.sm {
    font-size: .85rem;
  }

  &.ico {
    color: $th-alternate-dark;
  }

  &:hover {
    text-decoration: none;
    color: $th-black;
  }

  &.taamana {
    color: $th-alternate-medium;
    &:hover, &.active, &.focus {
      color: $th-alternate-dark;
    }
  }
}

.sb-icon {
  box-shadow: none !important;
}


