.shares {
  .price {
    font-weight: bold;
    color: $th-black;
    text-align: center;
  }

  .number {
    font-weight: bold;
    text-align: center;
  }
}

.theme {

  .ico {
    background-color: $th-alternate-light;
    color: $th-alternate-dark;
    border: solid 3px $th-alternate-light;
    opacity: .7;
    border-radius: .25rem;

    &.active {
      color: $th-black;
      opacity: 1;
    }
  }

  &.card {
    min-height: 70px;
    margin-bottom: .5rem;
    cursor: pointer;
    border: solid 2px $th-alternate-light;

    &.active, &:hover {
      border: solid 2px $th-alternate-darker;
      opacity: 1;

      color: $th-alternate-lighter;
      background-color: $th-alternate-darker;
    }
  }

}
