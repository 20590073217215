label {
  font-size: .8em;
  color: #6c757d; // text-muted
  margin-bottom: .2rem;

  &.checkbox {
    color: $th-black;
    margin-left: .25rem;
    margin-bottom: 0;
    font-size: .875rem;
  }
}

.notice {
  color: #bdc0c4;
}

