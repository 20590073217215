@mixin mdtext {
  font-size: .9rem;
}

@mixin xs {
  font-size: .8rem;
}

@mixin sm {
  font-size: 1rem;
}

@mixin md {
  font-size: 1.3rem;
}

@mixin lg {
  font-size: 1.75rem;
}

@mixin xl {
  font-size: 2rem;
}

.mdtext {
  @include mdtext;
}

.xs {
  @include xs;
}

.sm {
  @include sm;
}

.md {
  @include md;
}

.lg {
  @include lg;
}

.xl {
  @include xl;
}

.smaller {
  font-size:70%;
  font-weight:400
}

.notification {
  color: $th_black !important;
  font-weight: bold;
}
